//
// WARNING
//
// Do not make manual changes to this file.
// This file was generated by generate-icons.js.
//
//


/**
 * A list of all available icons in the icon set.
 */
export const availableIcons = ['icon-close', 'icon-li', 'icon-mail', 'icon-menu', 'icon-phone', 'icon-telegram'];

export const IconSvg = {
  'icon-close': <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16"><path fill='currentColor' fillRule="evenodd" d="m8 10.122 5.303 5.303a1.5 1.5 0 0 0 2.122-2.122L10.12 8l5.304-5.303A1.5 1.5 0 0 0 13.302.576L8 5.879 2.697.576a1.5 1.5 0 1 0-2.122 2.12L5.88 8 .576 13.304a1.5 1.5 0 1 0 2.122 2.12z" clipRule="evenodd"/></svg>,
  'icon-li': <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" fill="none" viewBox="0 0 8 9"><path fill="url(#a)" stroke='currentColor' d="M.707 4.01h4.657v4.657H.707z" transform="rotate(-45 .707 4.01)"/><defs><linearGradient id="a" x1="1.798" x2="1.798" y1="7.467" y2="9.392" gradientUnits="userSpaceOnUse"><stop stop-color="#EC6522"/><stop offset="1" stop-color="#E34B28"/></linearGradient></defs></svg>,
  'icon-mail': <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" viewBox="0 0 15 11"><path fill='currentColor' d="M14.166 1.5c0-.733-.6-1.333-1.333-1.333H2.166C1.433.167.833.767.833 1.5v8c0 .733.6 1.333 1.333 1.333h10.667c.733 0 1.333-.6 1.333-1.333zm-1.333 0L7.5 4.827 2.166 1.5zm0 8H2.166V2.833L7.5 6.167l5.333-3.334z"/></svg>,
  'icon-menu': <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25"><path fill='currentColor' d="M4 18.5a.97.97 0 0 1-.712-.288A.97.97 0 0 1 3 17.5q0-.424.288-.712A.97.97 0 0 1 4 16.5h16q.425 0 .713.288T21 17.5q0 .424-.288.713A.96.96 0 0 1 20 18.5zm0-5a.97.97 0 0 1-.712-.288A.97.97 0 0 1 3 12.5q0-.424.288-.712A.97.97 0 0 1 4 11.5h16q.425 0 .713.288T21 12.5q0 .424-.288.713A.96.96 0 0 1 20 13.5zm0-5a.97.97 0 0 1-.712-.288A.97.97 0 0 1 3 7.5q0-.424.288-.712A.97.97 0 0 1 4 6.5h16q.425 0 .713.288.288.289.287.712 0 .424-.288.713A.96.96 0 0 1 20 8.5z"/></svg>,
  'icon-phone': <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" fill="none" viewBox="0 0 14 13"><path fill='currentColor' d="m9.586 8.76.304-.301-.706-.71-.302.302zm1.324-.428 1.274.693.476-.879-1.273-.692zm1.518 2.069-.946.942.704.708.947-.941zm-1.524 1.244c-.966.091-3.466.01-6.174-2.682l-.706.709c2.955 2.938 5.768 3.082 6.974 2.97zM4.73 8.963C2.15 6.397 1.72 4.24 1.668 3.302l-.999.057c.067 1.178.597 3.57 3.355 6.313zm.916-4.12.192-.19-.704-.709-.192.19zM5.99 2.23l-.84-1.122-.8.6.84 1.122zM2.322.862l-1.046 1.04.705.71 1.046-1.04zm2.972 3.627-.353-.354H4.94l-.002.003a.7.7 0 0 0-.085.105 1 1 0 0 0-.109.212 1.4 1.4 0 0 0-.058.714c.09.576.486 1.338 1.504 2.35l.705-.709c-.953-.947-1.18-1.523-1.221-1.795a.4.4 0 0 1 .006-.212l-.02.026-.006.007-.007.006zm.896 3.03C7.208 8.531 7.974 8.926 8.55 9.014c.295.045.533.01.714-.058a1 1 0 0 0 .28-.157l.033-.03.005-.004.002-.002V8.76l-.351-.355c-.354-.355-.352-.355-.352-.355l.002-.002.001-.001.004-.003.007-.007.025-.02q.01-.006-.005.001a.44.44 0 0 1-.213.007c-.276-.043-.856-.27-1.808-1.216zM5.15 1.106C4.47.199 3.134.056 2.322.862l.705.71c.355-.353.984-.317 1.322.135zM1.668 3.303c-.013-.231.093-.472.313-.691l-.706-.71c-.358.357-.64.86-.606 1.457zm9.814 8.04c-.183.182-.38.285-.577.303l.093.995c.49-.046.89-.292 1.19-.589zm-5.644-6.69c.656-.653.705-1.684.152-2.423l-.8.6a.85.85 0 0 1-.057 1.115zm6.346 4.372c.545.296.63.994.245 1.376l.705.709c.894-.889.618-2.37-.473-2.963zM9.89 8.46a.86.86 0 0 1 1.02-.126l.478-.878a1.86 1.86 0 0 0-2.203.295z"/></svg>,
  'icon-telegram': <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" fill="none" viewBox="0 0 33 32"><g clipPath="url(#a)"><path fill='currentColor' fillRule="evenodd" d="M21.638 9.338a1.125 1.125 0 0 1 1.546 1.22l-1.7 10.317c-.166.996-1.258 1.566-2.17 1.07a44 44 0 0 1-2.918-1.72c-.51-.334-2.072-1.402-1.88-2.163.165-.65 2.79-3.094 4.29-4.546.588-.571.32-.9-.375-.375-1.727 1.303-4.499 3.285-5.415 3.843-.809.492-1.23.576-1.734.492-.92-.153-1.773-.39-2.469-.678-.94-.39-.894-1.683 0-2.06z" clipRule="evenodd"/><path stroke='currentColor' d="M.682 16c0-8.56 6.94-15.5 15.5-15.5s15.5 6.94 15.5 15.5-6.94 15.5-15.5 15.5S.682 24.56.682 16Z"/></g><defs><clipPath id="a"><path fill='currentColor' d="M33 0H0v32h33z"/></clipPath></defs></svg>,
};
