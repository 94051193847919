import React from 'react'

function ConnectBlock({ title, subtitle, image }) {
    return (
        <div className='w-full m-auto lg:m-0'>
            <div className='w-full flex justify-center items-stretch border-2 border-[#833E20] p-2 bg-[#080913] '>
                <div className='flex flex-col md:flex-col-reverse justify-between h-full'>
                    <div>
                        <p className='text-white text-lg lg:text-[30px] font-bold space lg:leading-[48px]'>{title}</p>
                        <p className='text-white text-xs lg:text-base leading-[18px] w-full m-auto md:mt-4 md:h-[150px]'>{subtitle}</p>
                    </div>
                    <img
                        className='max-w-[245px] lg:max-w-[unset] lg:h-[230px] lg:object-cover w-full m-auto'
                        src={image}
                        alt="navigate"
                    />
                </div>
            </div>
        </div>
    )
}

export default ConnectBlock
