import React, { useState } from 'react'
import blinker1 from '../images/blinkers/1.png'
import blinker2 from '../images/blinkers/2.png'
import blinker3 from '../images/blinkers/3.png'
import blinker4 from '../images/blinkers/4.png'
import blinker5 from '../images/blinkers/5.png'
import blinker6 from '../images/blinkers/6.png'
import prevBtn from '../images/prevBtnLaunch.png'
import nextBtn from '../images/nextBtnLaunch.png'
import { useLanguage } from "../Helpers/LanguageContext";
import { cn } from '../utills/cn';

function Launch() {
    const { languageData } = useLanguage();
    const levels = [
        { name: languageData?.T_skin1, image: blinker1, desc: languageData?.T_skin1Desc },
        { name: languageData?.T_skin2, image: blinker2, desc: languageData?.T_skin2Desc },
        { name: languageData?.T_skin3, image: blinker3, desc: languageData?.T_skin3Desc },
        { name: languageData?.T_skin4, image: blinker4, desc: languageData?.T_skin4Desc },
        { name: languageData?.T_skin5, image: blinker5, desc: languageData?.T_skin5Desc },
        { name: languageData?.T_skin6, image: blinker6, desc: languageData?.T_skin6Desc },
    ]

    const [currentLevelIndex, setCurrentLevelIndex] = useState(0)
    const [fade, setFade] = useState(true)

    const handleNext = () => {
        setFade(false)
        setTimeout(() => {
            setCurrentLevelIndex((prevIndex) =>
                prevIndex < levels.length - 1 ? prevIndex + 1 : 0
            )
            setFade(true)
        }, 200)
    }

    const handleBack = () => {
        setFade(false)
        setTimeout(() => {
            setCurrentLevelIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : levels.length - 1
            )
            setFade(true)
        }, 200)
    }

    const currentLevel = levels[currentLevelIndex]

    return (
        <div className='flex items-center justify-between max-w-[340px] md:max-w-[700px] m-auto lg:mt-12'>
            <img className='w-[40px] md:w-[56px] active:scale-95 duration-100 cursor-pointer' onClick={handleBack} src={prevBtn} alt="prevBtn" />
            <div className='max-w-[240px] md:max-w-[517px] w-full h-[342px] md:h-[410px] flex justify-center items-center'>
                <div>
                    <div className='m-auto max-w-[150px] lg:max-w-[190px] w-full bg-[#282828B3] border border-[#4D4D4D] py-2 lg:py-3 rounded-[100px]'>
                        <p className='text-[#EC6522] text-xs md:text-base text-center sora'>{currentLevel.name}</p>
                    </div>
                    <div
                        className={cn('transition-opacity duration-300 mt-2', {
                            'opacity-100': fade,
                            'opacity-0': !fade,
                        })}
                    >
                        <img className='mt-2.5 h-[150px] md:h-[280px] object-contain m-auto md:mt-10' src={currentLevel.image} alt='blinker' />
                    </div>
                    <p className='text-white text-xs md:text-base text-center md:text-left mt-6 h-[120px] md:h-[90px]'>{currentLevel.desc}</p>
                </div>
            </div>
            <img className='w-[40px] md:w-[56px] active:scale-95 duration-100 cursor-pointer' onClick={handleNext} src={nextBtn} alt="nextBtn" />
        </div>
    )
}

export default Launch
