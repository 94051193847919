import React, { useState } from 'react'
import { Icon } from '../components/lib/icon'
import { Link } from 'react-router-dom'
import pressHero from '../images/press-hero.png'
import logo1 from '../images/press-logo/1.png'
import logo2 from '../images/press-logo/2.png'
import logo3 from '../images/press-logo/3.png'
import logo4 from '../images/press-logo/4.png'
import logo5 from '../images/press-logo/5.png'
import arrow from '../images/spoiler-arrow.svg'
import { cn } from '../utills/cn'
import { useLanguage } from "../Helpers/LanguageContext";
import { Helmet } from 'react-helmet-async';

function Press() {
    const { languageData } = useLanguage();
    const releases = [
        { id: 1, title: languageData?.P_spoiler1Title, content: languageData?.P_spoiler1SubTitle },
        { id: 2, title: languageData?.P_spoiler2Title, content: languageData?.P_spoiler2SubTitle },
        { id: 3, title: languageData?.P_spoiler3Title, content: languageData?.P_spoiler3SubTitle },
    ];
    return (
        <section className='bg-starBg bg-center pb-10 pt-[50px] lg:pt-[100px] px-4'>
            <Helmet>
                <title>BPX - Press</title>
            </Helmet>
            <div className='mt-4 max-w-[960px] w-full m-auto'>

                <div className='lg:flex items-center justify-between'>
                    <div>
                        <p className='text-2xl md:text-[40px] font-bold leading-9 text-white'>{languageData?.P_title}
                        </p>
                        <p className='text-xs md:text-base text-white my-3 lg:my-6'>{languageData?.P_subtitle}</p>
                        <div>
                            <p className='text-xs md:text-base text-white font-bold'>Sabrina Vasconcellos</p>
                            <Link to='mailto:vasconcellos@latamintersectpr.com'>
                                <p className='flex items-center gap-2 text-[#ED6821] text-xs md:text-base hover:underline mt-2'><Icon className='mr-1 md:w-6 h-4 md:h-6' icon="icon-mail" fillColor="white" />bina.vasconcellos@latamintersectpr.com</p>
                            </Link>
                            <Link to='tel:21 98867-6363'>
                                <p className='flex items-center gap-2 text-[#ED6821] text-xs md:text-base hover:underline mt-1'><Icon className='mr-1 md:w-6 h-4 md:h-6' icon="icon-phone" fillColor="white" />21 98867-6363</p>
                            </Link>
                        </div>
                        <div className='mt-4'>
                            <p className='text-xs md:text-base text-white font-bold'>Bruno Lino</p>
                            <Link to='mailto:bruno.lino@latamintersectpr.com'>
                                <p className='flex items-center gap-2 text-[#ED6821] text-xs md:text-base hover:underline mt-2'><Icon className='mr-1 w-4 md:w-6 h-4 md:h-6' icon="icon-mail" fillColor="white" />bina.bruno.lino@latamintersectpr.com</p>
                            </Link>
                            <Link to='tel:11 98596-8063'>
                                <p className='flex items-center gap-2 text-[#ED6821] text-xs md:text-base hover:underline mt-1'><Icon className='mr-1 w-4 md:w-6 h-4 md:h-6' icon="icon-phone" fillColor="white" />11 98596-8063</p>
                            </Link>
                        </div>
                    </div>
                    <img className='max-w-[240px] lg:max-w-[360px] w-full m-auto lg:m-0 mt-4' src={pressHero} alt="pressHero" />
                </div>
            </div>
            <div>

                <div className='pressLine w-full h-[1px] mt-6'></div>
                <p className='text-2xl md:text-[40px] text-white font-bold text-center my-4 md:my-8'>{languageData?.P_logoTitle}</p>
                <div className='w-full grid gap-4 px-2 lg:flex max-w-[960px] m-auto'>
                    <div className='w-full bg-black/60 border border-[#EB6522] rounded h-[80px] md:h-[180px] flex justify-center items-center'>
                        <img className='max-w-[180px] md:max-w-[280px] w-full m-auto' src={logo1} alt="logo1" />
                    </div>
                    <div className='w-full bg-black/60 border border-[#EB6522] rounded h-[80px] md:h-[180px] flex justify-center items-center'>
                        <img className='max-w-[55px] md:max-w-[90px] w-full m-auto' src={logo2} alt="logo2" />
                    </div>
                    <div className='w-full bg-black/60 border border-[#EB6522] rounded h-[80px] md:h-[180px] flex justify-center items-center'>
                        <img className='max-w-[140px] md:max-w-[200px] w-full m-auto' src={logo3} alt="logo3" />
                    </div>
                </div>
                <div className='w-full grid gap-4 px-2 lg:flex lg:justify-center mt-4'>
                    <div className='w-full lg:max-w-[305px] bg-black/60 border border-[#EB6522] rounded h-[80px] md:h-[180px] flex justify-center items-center'>
                        <img className='max-w-[90px] md:max-w-[130px] w-full m-auto' src={logo4} alt="logo4" />
                    </div>
                    <div className='w-full lg:max-w-[305px] bg-black/60 border border-[#EB6522] rounded h-[80px] md:h-[180px] flex justify-center items-center'>
                        <img className='max-w-[59px] md:max-w-[70px] w-full m-auto' src={logo5} alt="logo5" />
                    </div>
                </div>
                <div className="flex justify-center mt-8">
                    <button
                        className="bg-[#ED6821] text-white rounded p-4 font-bold text-xs md:text-base text-center"
                        onClick={() => {
                            const link = document.createElement('a');
                            link.href = '/logos-bp.zip';
                            link.download = 'logos-bp.zip';
                            link.click();
                        }}
                    >
                        {languageData?.P_logoBtn}
                    </button>
                </div>


                <div className='pressLine w-full h-[1px] mt-6'></div>
            </div>
            <div className='max-w-[960px] w-full m-auto'>
                <p className='text-2xl md:text-[40px] text-white font-bold text-center my-6 md:my-10 '>{languageData?.P_spoilerTitle}</p>
                
                <Link to='/press/blink-day' target='_blank'>
                    <div className="w-full border border-[#EB6522] rounded-lg overflow-hidden cursor-pointer">
                        <div

                            className="flex justify-between items-center w-full px-4 md:px-8 py-3 md:py-5 text-white bg-black/60 focus:outline-none"
                        >
                            <p className="text-xs md:text-2xl">Blink Day</p>
                            <img className='duration-200 transform -rotate-90 w-8' src={arrow} alt="arrow" />
                        </div>

                    </div>
                </Link>
                <Link to='/press/blink-planet-democratizar-ativos-digitais' target='_blank'>
                    <div className="w-full border border-[#EB6522] rounded-lg overflow-hidden cursor-pointer mt-4">
                        <div

                            className="flex justify-between items-center w-full px-4 md:px-8 py-3 md:py-5 text-white bg-black/60 focus:outline-none"
                        >
                            <p className="text-xs md:text-2xl">Democratização do mercado de ativos digitais</p>
                            <img className='duration-200 transform -rotate-90 w-8' src={arrow} alt="arrow" />
                        </div>

                    </div>
                </Link>
                <div className="w-full border border-[#EB6522] rounded-lg overflow-hidden cursor-pointer mt-4">
                    <div
                        onClick={() => {
                            const link = document.createElement('a');
                            link.href = '/Evento.pdf';
                            link.download = 'Evento gratuito aborda o futuro das finanças descentralizadas.pdf';
                            link.click();
                        }}
                        className="flex justify-between items-center w-full px-4 md:px-8 py-3 md:py-5 text-white bg-black/60 focus:outline-none"
                    >
                        <p className="text-xs md:text-2xl">AirDAO Brasil Meetup - Minas Gerais</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M12 16.735L7 11.735L8.4 10.285L11 12.885V4.73499H13V12.885L15.6 10.285L17 11.735L12 16.735ZM6 20.735C5.45 20.735 4.97933 20.5393 4.588 20.148C4.19667 19.7567 4.00067 19.2857 4 18.735V15.735H6V18.735H18V15.735H20V18.735C20 19.285 19.8043 19.756 19.413 20.148C19.0217 20.54 18.5507 20.7357 18 20.735H6Z" fill="white" />
                        </svg>
                    </div>

                </div>
                {/*
                <div className='grid gap-4 px-8'>
                    {releases.map((release) => (
                        <Spoiler key={release.id} title={release.title} content={release.content} />
                    ))}
                </div>
                */}
            </div>
        </section>
    )
}

export default Press

const Spoiler = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="w-full border border-[#EB6522] rounded-lg overflow-hidden cursor-pointer">
            <div
                onClick={() => setIsOpen(!isOpen)}
                className="flex justify-between items-center w-full px-4 md:px-8 py-3 md:py-5 text-white bg-black/60 focus:outline-none"
            >
                <p className="text-xs md:text-2xl">{title}</p>
                <img className={cn('duration-200',
                    isOpen ? 'transform rotate-180' : 'transform rotate-0'
                )} src={arrow} alt="arrow" />
            </div>
            <div
                className={cn(
                    "transition-all duration-300 ease-in-out",
                    isOpen ? "max-h-screen" : "max-h-0"
                )}
            >
                <div className="px-4 md:px-8 py-3 h-full bg-[#1a1a1a] text-white break-words">
                    {content}
                </div>
            </div>
        </div>
    );
};