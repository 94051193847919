import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Icon } from "./components/lib/icon";
import Traffic from "./pages/Traffic";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Press from "./pages/Press";
import Partners from "./pages/Partners";
import { LanguageProvider } from "./Helpers/LanguageContext";
import Collection from "./pages/Collection";
import { HelmetProvider } from 'react-helmet-async';
import mixpanel from 'mixpanel-browser';
import TrackPageView from "./components/TrackPageView";
import BlinkDay from "./pages/BlinkDay";
import Digital from "./pages/Digital";

function App() {
  mixpanel.init("bc70b8ef1bbe8618038ac48a2f1962b3", {
    debug: true,
  });
  return (
    <div className="App">
      <HelmetProvider>
        <LanguageProvider>
          <BrowserRouter>
            <Header />
            
            <TrackPageView />
            <Routes>
              <Route path="/" element={<Traffic />} />
              <Route path="/traffic" element={<Traffic />} />
              <Route path="/utility" element={<Partners />} />
              <Route path="/press" element={<Press />} />
              <Route path="/press/blink-day" element={<BlinkDay />} />
              <Route path="/press/blink-planet-democratizar-ativos-digitais" element={<Digital />} />
              <Route path="/collections" element={<Collection />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </LanguageProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
