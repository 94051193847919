import React from 'react'

function NavigateBlock({ title, subtitle, image, span }) {
    return (
        <div className='w-full m-auto lg:m-0'>
            <div className='w-full flex justify-center md:h-[500px] items-stretch border-2 border-[#833E20] bg-[#080913] p-2 lg:p-4'>
                <div className='flex flex-col lg:flex-col-reverse justify-between h-full'>
                    <div>
                        <p className='text-white text-lg lg:text-[30px] font-bold space leading-[24px] lg:leading-[38px]'>{title} <br /> <span className='text-[#EC6522] space'>{span}</span></p>
                        <p className='text-white text-xs lg:text-base leading-[18px] w-full m-auto my-2 lg:h-[70px] lg:mt-4'>{subtitle}</p>
                    </div>
                    <img
                        className='max-w-[245px] lg:max-w-[unset] lg:h-[280px] lg:object-contain w-full m-auto mt-4 lg:mt-0'
                        src={image}
                        alt="navigate"
                    />
                </div>
            </div>
        </div>

    )
}

export default NavigateBlock
