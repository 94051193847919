import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const pageNames = {
  '/': 'Traffic',
  '/traffic': 'Traffic',
  '/utility': 'Utility',
  '/press': 'Press',
  '/collections': 'Collections',
};

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    const pageName = pageNames[location.pathname] || location.pathname;

    mixpanel.track("Page View", {
      page_name: pageName,
    });
  }, [location]);

  return null;
};

export default TrackPageView;
