import React, { useState } from "react";
import arrow from "../images/spoiler-arrow.svg";
import { cn } from "../utills/cn";
import { useLanguage } from "../Helpers/LanguageContext";
import { Helmet } from "react-helmet-async";
import blink from "../images/digital.png";
import { Link } from "react-router-dom";

function Digital() {
  const { languageData } = useLanguage();

  return (
    <section className="bg-starBg2 bg-center bg-cover pb-10 pt-[50px] lg:pt-[100px] px-4">
      <Helmet>
        <title>BPX - Blink Day</title>
      </Helmet>
      <div className="mt-4 max-w-[960px] w-full m-auto">
        <p className="text-white text-[32px] font-bold mt-6">
          Blink Planet quer democratizar o acesso ao mercado de ativos digitais
          com plataforma fácil de usar
        </p>
        <ul className="list-disc mt-4">
          <li className="text-[#EC6522] !italic text-base ml-7">
            Empresa se destaca ao simplificar o processo de aquisição de ativos
            digitais, permitindo que usuários comprem usando o cartão de
            crédito, eliminando a necessidade de gerenciar carteiras complexas
            ou criptomoedas
          </li>
          <li className="text-[#EC6522] !italic text-base ml-7 mt-2">
            Plataforma torna fácil a transição para um novo patamar digital, que
            garante autenticidade e exclusividade em cada transação
          </li>
        </ul>
        <img
          className="max-w-[440px] w-full m-auto my-6"
          src={blink}
          alt="blinkDay"
        />
        <p className="text-base text-white mt-6 tracking-[0.4px] leading-6">
          São Paulo, 05 de novembro - A Blink Planet, plataforma dedicada à
          transação de ativos digitais, foi lançada nesta terça-feira, 05, no
          Brasil, com a promessa de transformar a experiência de compra e venda
          nesse mercado em rápido crescimento. Com foco na acessibilidade,
          rapidez nas transações e segurança, a empresa se posiciona como uma
          porta de entrada para usuários novatos e uma opção atraente para os
          mais experientes na Web3.
        </p>
        <p className="text-base text-white mt-6 tracking-[0.4px] leading-6">
          “Oferecemos uma alternativa que coloca o usuário no centro, permitindo
          que ele tenha total controle sobre seus ativos digitais e garantindo
          uma experiência prática, personalizada e segura”, afirma Adriano
          Mendes, diretor de Negócios da Blink Planet.
        </p>
        <p className="text-base text-white mt-6 tracking-[0.4px] leading-6">
          A Web3 representa a próxima fase da internet, marcada pela
          descentralização do controle e da propriedade, onde os usuários são os
          únicos proprietários de seus ativos digitais e dados. O setor está em
          crescimento. Um relatório da LAMEA indica que os segmentos de Web3 e
          blockchain terão um avanço de 42,1% até 2028 na América Latina. No
          entanto, a complexidade de muitas plataformas ainda limita a adoção
          generalizada, especialmente nesta região, onde barreiras como a
          experiência do usuário e a falta de familiaridade com a tecnologia
          blockchain são desafios constantes.
        </p>
        <p className="text-base text-white mt-6 tracking-[0.4px] leading-6">
          Nesse contexto, a Blink Planet se destaca ao simplificar o processo de
          aquisição de ativos digitais, permitindo que usuários comprem usando o
          cartão de crédito, eliminando a necessidade de gerenciar carteiras
          complexas ou criptomoedas. Essa abordagem híbrida oferece o melhor dos
          dois mundos: a transparência e propriedade do usuário característicos
          da Web3, aliados à rapidez e simplicidade dos métodos de pagamento
          tradicionais e centralizados, já conhecidos do grande público.
        </p>
        <p className="text-base text-white mt-6 tracking-[0.4px] leading-6">
          Com mais de 100 mil ativos digitais e 16 coleções já disponíveis, a
          plataforma não apenas facilita o acesso, mas também proporciona uma
          experiência interativa e divertida na compra de ativos digitais, que
          não ficam apenas no mundo virtual. Eles podem gerar benefícios
          concretos no mundo real, como acesso a experiências exclusivas,
          eventos e recompensas, trazendo oportunidades que vão além do digital.
        </p>
        <p className="text-base text-white mt-6 tracking-[0.4px] leading-6">
          Isso é oferecido por meio do acesso ao BP Explorer, criado para
          complementar a experiência dos usuários. Esse aplicativo, integrado ao
          Telegram, proporciona uma forma dinâmica de interação com a
          plataforma. Ao completar missões e desafios, os usuários acumulam
          ‘Blinkers’, pontos que podem ser trocados por ativos digitais ou
          benefícios.
        </p>
        <p className="text-base text-white mt-6 tracking-[0.4px] leading-6">
          “Queremos criar um ambiente onde todos possam explorar e investir em
          ativos digitais de forma descomplicada e segura, por isso, unimos a
          transparência da Web3 com a simplicidade dos métodos de pagamento
          tradicionais para oferecer uma experiência descomplicada e que atende
          às necessidades de todos”, afirma Adriano.
        </p>
      </div>
    </section>
  );
}

export default Digital;
