import React, { useState } from 'react'
import arrow from '../images/spoiler-arrow.svg'
import { cn } from '../utills/cn'
import { useLanguage } from "../Helpers/LanguageContext";
import { Helmet } from 'react-helmet-async';
import blink from '../images/blink-day.png'
import { Link } from 'react-router-dom';

function BlinkDay() {
    const { languageData } = useLanguage();
    const releases = [
        { id: 1, title: languageData?.P_spoiler1Title, content: languageData?.P_spoiler1SubTitle },
        { id: 2, title: languageData?.P_spoiler2Title, content: languageData?.P_spoiler2SubTitle },
        { id: 3, title: languageData?.P_spoiler3Title, content: languageData?.P_spoiler3SubTitle },
    ];
    return (
        <section className='bg-starBg2 bg-center bg-cover pb-10 pt-[50px] lg:pt-[100px] px-4'>
            <Helmet>
                <title>BPX - Blink Day</title>
            </Helmet>
            <div className='mt-4 max-w-[960px] w-full m-auto'>
                <p className='text-white text-[32px] font-bold mt-6'>Blink Planet dá até R$250 em cartões pré-pagos para a Black Friday</p>
                <p className='text-[#EC6522] text-2xl font-bold mt-4'>Ação da empresa envolve a interação com o aplicativo Blink Planet Explorer</p>
                <p className='text-base text-white mt-6 tracking-[0.4px] leading-6'>Troca de prêmios acontece no dia 25 de novembro</p>
                <img className='max-w-[412px] w-full m-auto my-6' src={blink} alt="blinkDay" />
                <p className='text-base text-white mt-6 tracking-[0.4px] leading-6'>Em vez de convidar os consumidores a comprarem, nesta Black Friday a Blink Planet, plataforma dedicada à transação de ativos digitais, quer colocar dinheiro no bolso dos usuários. A ação especial para a Black Friday da Blink Planet oferece aos usuários a chance de ganhar até R$250 em cartões pré-pagos.</p>
                <p className='text-base text-white mt-6 tracking-[0.4px] leading-6'>A promoção acontece dentro do aplicativo BPExplorer, onde os participantes podem acumular pontos por meio da funcionalidade "Tap to Earn", que consiste em clicar no personagem Blinker. Além disso, é possível somar pontos indicando amigos ou adquirindo ativos digitais na Blink Planet.</p>
                <p className='text-base text-white mt-6 tracking-[0.4px] leading-6'>Para retirar o prêmio, os participantes precisam obter no mínimo 15 mil pontos até 25 de novembro. Nessa data, cada usuário com pontuação válida poderá solicitar um cartão pré-pago, que será entregue por ordem de pedido até o limite de R$10 mil.</p>
                <p className='text-base text-white mt-6 tracking-[0.4px] leading-6'>O app exibe, ainda, um ranking diário, permitindo que os usuários acompanhem seus desempenhos, e também seleciona posições aleatórias para prêmios extras, que somarão mais R$5 mil.</p>
                <p className='text-[#EC6522] text-2xl font-bold mt-4'>Sobre o universo Blink Planet:</p>
                <p className='text-base text-white mt-6 tracking-[0.4px] leading-6'>Blink Planet é uma plataforma global que reúne artistas, desenvolvedores e especialistas em tecnologia para criar experiências digitais únicas. Nela, os usuários podem comprar, vender e colecionar ativos digitais de forma simples, rápida e segura. Uma plataforma inovadora, confiável e que foi projetada para transformar a maneira como as pessoas interagem com ativos digitais, proporcionando uma experiência fácil e acessível.</p>
                <p className='text-base text-white mt-6 tracking-[0.4px] leading-6 font-bold'>Para mais informações ou agendar entrevistas, fale com:</p>
                <p className='text-base text-white mt-6 tracking-[0.4px] leading-6'>Bruno Lino |
                    <span className='text-[#EC6522]'>
                        {" "} <Link className='hover:underline' to='mailto:bruno.lino@latamintersectpr.com'>bruno.lino@latamintersectpr.com</Link>
                    </span>
                    {" "} | (11) 98596-8063
                </p>
                <p className='text-base text-white mt-6 tracking-[0.4px] leading-6'>Bina Vasconcellos |
                    <span className='text-[#EC6522]'>
                        {" "} <Link className='hover:underline' to='mailto:bina.vasconcellos@latamintersectpr.com'>bina.vasconcellos@latamintersectpr.com</Link>
                    </span>
                    {" "} | (21) 98867-6363
                </p>
            </div>


        </section>
    )
}

export default BlinkDay

const Spoiler = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="w-full border border-[#EB6522] rounded-lg overflow-hidden cursor-pointer">
            <div
                onClick={() => setIsOpen(!isOpen)}
                className="flex justify-between items-center w-full px-4 md:px-8 py-3 md:py-5 text-white bg-black/60 focus:outline-none"
            >
                <p className="text-xs md:text-2xl">{title}</p>
                <img className={cn('duration-200',
                    isOpen ? 'transform rotate-180' : 'transform rotate-0'
                )} src={arrow} alt="arrow" />
            </div>
            <div
                className={cn(
                    "transition-all duration-300 ease-in-out",
                    isOpen ? "max-h-screen" : "max-h-0"
                )}
            >
                <div className="px-4 md:px-8 py-3 h-full bg-[#1a1a1a] text-white break-words">
                    {content}
                </div>
            </div>
        </div>
    );
};