import React, { useState } from "react";
import homeHero from "../images/home-hero.png";
import NavigateBlock from "../components/NavigateBlock";
import navigate1 from "../images/navigate/1_1.png";
import navigate2 from "../images/navigate/2.png";
import navigate3 from "../images/navigate/1_3.png";
import Launch from "../components/Launch";
import Feture from "../components/Feture";
import blinker from "../images/blink-space.png";
import intro from "../images/intro-hero.png";
import community from "../images/community-hero.png";
import ContactModal from "../components/ContactModal";
import { Link } from "react-router-dom";
import { useLanguage } from "../Helpers/LanguageContext";
import whatIsHero from "../images/whatIs-hero.png";
import skinHero from "../images/skin-hero.png";
import { Helmet } from "react-helmet-async";
import GearUp from "../components/GearUp";
import Media from "../components/Media";
import MediaSlideBar from "../components/MediaSlideBar";

function Traffic() {
  const [contactModal, setContactModal] = useState(false);
  const { languageData } = useLanguage();
  return (
    <>
      <Helmet>
        <title>BPX - Traffic</title>
      </Helmet>
      <div className=" bg-center overflow-hidden">
        <section className="py-10 md:py-20 md:pt-28">
          <div className="max-w-[1255px] w-full m-auto px-4 relative mt-10 lg:mt-20">
            <h1 className="text-white font-bold sora text-2xl lg:text-[80px] uppercase leading-6 lg:leading-[96px] relative z-10">
              {" "}
              <span className="text-[#29ABE2]">
                {languageData?.T_homeTitle}{" "}
              </span>
              {languageData?.T_homeTitleSpan1}
              <br />
              {languageData?.T_homeTitleSpan2}
            </h1>
            <div className="flex justify-center lg:justify-start gap-2 items-end">
              <div className="mt-4 mb-2 relative z-10">
                <p className="text-xs lg:text-xl text-white leading-[18px] lg:max-w-[830px] w-full lg:my-5">
                  {languageData?.T_homeSubTitle}
                </p>
                <Link
                  to="https://bloom-patient-4f6.notion.site/12bed321375d80988f81ed005e02d521"
                  target="_blank"
                >
                  <button className="defaultBtnGradient mt-4 text-white text-xs lg:text-2xl font-bold px-2 py-2 lg:py-4 uppercase hover:scale-[0.98] duration-100 rounded-lg sora">
                    {languageData?.T_homeBtn}
                  </button>
                </Link>
              </div>
              <img
                className="max-w-[155px] w-full lg:hidden"
                src={homeHero}
                alt="homeHero"
              />
            </div>
          </div>
        </section>
        <img
          className="absolute right-0 top-[270px] w-[420px] hidden lg:block"
          src={homeHero}
          alt="homeHero"
        />
        <section className="px-4 py-2 md:py-20">
          <div className="w-full p-4 lg:pt-8 max-w-[1255px] m-auto">
            <div className="flex items-center gap-2 md:gap-4 sora text-white font-bold text-2xl lg:text-[64px] max-w-[1255px] m-auto">
              <div className="shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]"></div>
              {languageData?.T_navigateTitle}
            </div>
            <p className="text-xs lg:text-[20px] text-white mt-2 lg:my-6 lg:leading-[30px]">
              {languageData?.T_navigateSubTitle}
            </p>
          </div>
          <div className="grid md:flex items-center justify-center max-w-[1255px] m-auto gap-4">
            <NavigateBlock
              title={languageData?.T_navigate1Title}
              span={languageData?.T_navigate1TitleSpan}
              subtitle={languageData?.T_navigate1SubTitle}
              image={navigate1}
            />
            <NavigateBlock
              title={languageData?.T_navigate2Title}
              span={languageData?.T_navigate2TitleSpan}
              subtitle={languageData?.T_navigate2SubTitle}
              image={navigate2}
            />
            <NavigateBlock
              title={languageData?.T_navigate3Title}
              span={languageData?.T_navigate3TitleSpan}
              subtitle={languageData?.T_navigate3SubTitle}
              image={navigate3}
            />
          </div>
        </section>
        {/*
            <div className='w-full p-4 lg:pt-8 max-w-[1255px] m-auto'>
                <div className='flex items-center gap-2 md:gap-4 sora text-white font-bold text-2xl lg:text-[64px] max-w-[1255px] m-auto'>
                    <div className='shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]'></div>
                    BPX + You
                </div>
                <p className='text-xs lg:text-[20px] text-white mt-2 lg:my-6 lg:leading-[30px]'>Are you a community manager, traffic manager, or affiliate manager? This page is for influencers looking to connect audiences with BP Explorer, bringing engagement and value to their followers.</p>
            </div>
            
            <section className='py-5 pt-2 md:py-10 bg-skinSection bg-no-repeat bg-cover'>
                <div className='w-full p-4 lg:pt-8 max-w-[1255px] m-auto'>
                    <div className='flex items-center gap-2 md:gap-4 sora text-white font-bold text-2xl lg:text-[64px] max-w-[1255px] m-auto'>
                        <div className='shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]'></div>
                        {languageData?.T_skinTitle}
                    </div>
                    <p className='text-xs lg:text-[20px] text-white mt-2 lg:my-6 lg:leading-[30px]'>{languageData?.T_skinSubTitle}</p>
                </div>
                <div className='px-2'>
                    <div className='bg-black/40 max-w-[1255px] m-auto w-full py-4 md:pb-[80px]'>
                        <Launch />
                    </div>
                </div>
                <div className='flex justify-center mt-5 md:mt-10'>
                    <Link to='https://bloom-patient-4f6.notion.site/12bed321375d80988f81ed005e02d521' target='_blank'>
                        <button className="defaultBtnGradient text-white text-xs lg:text-[20px] font-bold px-2 lg:px-4 py-2 lg:py-4 uppercase hover:scale-[0.98] duration-100 rounded-lg sora">
                            {languageData?.T_skinBtn}
                        </button>
                    </Link>
                </div>
            </section>
            */}
        <section className="py-5 pt-2 md:py-10 bg-skinSection bg-no-repeat bg-cover">
          <div className="w-full p-4 lg:pt-8 max-w-[1255px] m-auto">
            <div className="flex items-center gap-2 md:gap-4 sora text-white font-bold text-2xl lg:text-[64px] max-w-[1255px] m-auto">
              <div className="shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]"></div>
              {languageData?.T_skinNewTitle}
            </div>
          </div>
          <div className="lg:flex items-center justify-between max-w-[1255px] m-auto px-4 lg:mt-12">
            <img
              className="w-full max-w-[300px] lg:max-w-[700px] m-auto"
              src={skinHero}
              alt="skinHero"
            />
            <div className="">
              <p className="text-white text-sm lg:text-xl mt-4 lg:mt-0">
                {languageData?.T_skinNewSubTitle1}
              </p>
              <p className="text-xl lg:text-3xl font-bold text-[#29ABE2] my-2 lg:my-4">
                {languageData?.T_skinNewSubTitle2}
              </p>
              <p className="text-white text-sm lg:text-xl">
                {languageData?.T_skinNewSubTitle3}
              </p>
              <div className="flex justify-center lg:justify-start">
                <Link
                  to="https://bloom-patient-4f6.notion.site/12bed321375d80988f81ed005e02d521"
                  target="_blank"
                >
                  <button className="mt-2 md:mt-4 defaultBtnGradient text-white uppercase text-xs lg:text-2xl font-bold px-2 lg:px-4 py-2 lg:py-4 hover:scale-[0.98] duration-100 rounded-lg sora">
                    {languageData?.T_skinNewBtn}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="w-full p-4 lg:pt-8 max-w-[1255px] m-auto md:mt-12 relative">
            <div className="flex items-center gap-2 md:gap-4 sora text-white font-bold text-2xl lg:text-[64px] max-w-[1255px] m-auto">
              <div className="shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]"></div>
              {languageData?.T_communityTitle}
            </div>
            <p className="text-xs lg:text-[20px] text-white mt-2 lg:mt-8 lg:leading-[30px] max-w-[720px]">
              {languageData?.T_communitySubTitle}
            </p>
            <p className="text-white sora text-[20px] md:text-[32px] font-bold my-2 md:my-4">
              {languageData?.T_communityTitle2}
            </p>
            <p className="text-xs lg:text-[20px] text-white lg:leading-[30px] max-w-[720px]">
              {languageData?.T_communitySubTitle2}
            </p>
            <img
              className="lg:absolute right-0 w-full max-w-[340px] lg:max-w-[470px] top-6 z-[-1] m-auto mt-6"
              src={community}
              alt="community"
            />
            <div className="flex justify-center lg:justify-start">
              <Link
                to="https://bloom-patient-4f6.notion.site/12bed321375d80988f81ed005e02d521"
                target="_blank"
              >
                <button className="defaultBtnGradient mt-6 md:mt-12 text-white text-xs lg:text-2xl font-bold px-2 lg:px-4 py-2 lg:py-4 uppercase hover:scale-[0.98] duration-100 rounded-lg sora">
                  {languageData?.T_communityBtn}
                </button>
              </Link>
            </div>
          </div>
        </section>

        <section className="px-2 py-5 md:py-20 relative">
          <div className="max-w-[1255px] m-auto md:px-4">
            <p className="flex items-center gap-2 md:gap-4 space text-white font-bold text-2xl md:text-[64px] md:leading-[60px]">
              <div className="shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]"></div>
              {languageData?.T_roadmapTitle}
            </p>
            <p className="text-white text-xs md:text-xl leading-4 my-4 md:my-12">
              {languageData?.T_roadmapSubTitle}
            </p>
          </div>
          <Feture />
          <img
            className="absolute left-[-20px] top-[824px] hidden lg:block pl-4 w-[345px]"
            src={blinker}
            alt="arrow2"
          />
        </section>
        <div className="w-full h-1 whatIsLine"></div>
        <section className="px-4 py-4 md:py-10 bg-black/40">
          <div className="w-full max-w-[1255px] m-auto">
            <div className="flex items-center gap-2 md:gap-4 sora text-white font-bold text-2xl lg:text-[64px] max-w-[1255px] m-auto">
              <div className="shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]"></div>
              {languageData?.T_whatIsTitle}{" "}
              <span className="text-[#29ABE2]">
                {languageData?.T_whatIsTitleSpan}
              </span>
            </div>
            <div className="lg:flex justify-between items-center mt-6">
              <img
                className="max-w-[205px] lg:max-w-[445px] w-full m-auto lg:m-0"
                src={whatIsHero}
                alt="whatIsHero"
              />
              <div className="max-w-[670px] w-full mt-4 lg:mt-0">
                <p className="text-xs lg:text-xl text-white">
                  {languageData?.T_whatIsSubtitle1}
                </p>
                <p className="text-xs lg:text-xl text-white my-6">
                  {languageData?.T_whatIsSubtitle2}
                </p>
                <p className="text-xs lg:text-xl text-white font-bold">
                  {languageData?.T_whatIsSubtitle3}
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-4 lg:mt-8">
            <Link to="https://www.blinkplanet.com/" target="_blank">
              <button className="defaultBtnGradient text-white text-xs lg:text-2xl font-bold px-2 py-2 lg:py-4 uppercase hover:scale-[0.98] duration-100 rounded-lg sora">
                {languageData?.T_whatIsBtn}
              </button>
            </Link>
          </div>
        </section>
        <div className="w-full h-1 whatIsLine"></div>
        <section className=" pt-5 md:pt-10 relative bg-[#1D264A33]">
          <div className="w-full max-w-[1255px] m-auto">
            <div className="flex px-2 items-center gap-2 md:gap-4 sora text-white font-bold text-2xl lg:text-[64px] max-w-[1255px] m-auto lg:leading-[80px]">
              <div className="shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]"></div>
              What are the media <br /> talking about us
            </div>
            <Media />
          </div>
          <MediaSlideBar />
        </section>
        <section className="px-2 py-5 md:py-10 relative">
          {/* <div className='w-full h-[74px] bg-[#161C24] absolute left-0 bottom-[450px] hidden lg:block'></div> */}
          <div className="bg-[#080913] border-2 overflow-hidden border-[#833E20] duration-100 max-w-[330px] md:max-w-[700px] rounded-[5px] md:rounded-2xl lg:max-w-[1233px] m-auto w-full flex md:items-start mt-6 p-4 md:p-10 relative lg:my-10">
            <div className="md:max-w-[unset] m-auto md:m-[unset] lg:pl-6 relative z-10">
              <p className="text-white text-xl md:text-3xl lg:text-[48px] font-bold leading-6 md:leading-[50px] lg:mt-6">
                {languageData?.T_aboutTitle}
              </p>
              <p className="text-white text-xs md:text-base lg:text-[28px] leading-[18px] lg:leading-10 mt-2 max-w-[165px] md:max-w-[400px] lg:max-w-[540px] lg:my-6 lg:mt-14">
                {languageData?.T_aboutSubTitle}
              </p>
              <Link
                to="https://bloom-patient-4f6.notion.site/12bed321375d80988f81ed005e02d521"
                target="_blank"
              >
                <button className="defaultBtnGradient mt-4 text-white text-xs lg:text-2xl font-bold px-2 py-2 lg:py-4 uppercase hover:scale-[0.98] duration-100 rounded-lg sora">
                  {languageData?.T_aboutBtn}
                </button>
              </Link>
            </div>
            <img
              className=" absolute bottom-6 lg:bottom-10 right-[-20px] lg:right-14 w-[200px] lg:w-[500px]"
              src={intro}
              alt="intro"
            />
          </div>
        </section>
        {contactModal && <ContactModal setContactModal={setContactModal} />}
      </div>
    </>
  );
}

export default Traffic;
