import React, { useState } from 'react';
import { Icon } from './lib/icon';

function ContactModal({ setContactModal }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});

    const validateFields = () => {
        let fieldErrors = {};
        if (!firstName.trim()) fieldErrors.firstName = "First name is required.";
        if (!lastName.trim()) fieldErrors.lastName = "Last name is required.";
        if (!email.trim()) fieldErrors.email = "Email is required.";
        if (!message.trim()) fieldErrors.message = "Message is required.";

        setErrors(fieldErrors);
        return Object.keys(fieldErrors).length === 0;
    };

    const handleSubmit = () => {
        if (validateFields()) {
            console.log("Form submitted:", { firstName, lastName, email, message });
        }
    };

    return (
        <div className='fixed w-screen h-screen top-0 left-0 bg-black/90 z-50 flex justify-center items-center lg:py-14'>
            <div className='border-[#E33A0B] border-[3px] py-12 px-14 w-full lg:max-w-[850px] h-full bg-black relative overflow-scroll hide-scroll'>
                <p className='text-3xl lg:text-4xl text-white font-bold space'>Contact us</p>
                <Icon onClick={() => setContactModal(false)} className='w-4 h-4 lg:w-6 lg:h-6 absolute right-4 top-4 cursor-pointer' icon="icon-close" fillColor="white" />

                <div className='w-full lg:flex justify-between gap-6'>
                    <div className='mt-4 lg:mt-10 w-full'>
                        <p className='text-white text-xs lg:text-xl font-bold'>First name</p>
                        <input
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            className={`w-full bg-white/15 outline-none text-white text-xs lg:text-xl font-bold px-3 lg:px-7 py-2 lg:py-3.5 placeholder:text-white/30 mt-2 lg:mt-5 ${errors.firstName ? 'border border-red-500' : ''}`}
                            placeholder='Type your first name here'
                            type="text"
                        />
                        {errors.firstName && <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>}
                    </div>
                    <div className='mt-4 lg:mt-10 w-full'>
                        <p className='text-white text-xs lg:text-xl font-bold'>Last name</p>
                        <input
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            className={`w-full bg-white/15 outline-none text-xs text-white lg:text-xl font-bold px-3 lg:px-7 py-2 lg:py-3.5 placeholder:text-white/30 mt-2 lg:mt-5 ${errors.lastName ? 'border border-red-500' : ''}`}
                            placeholder='Type your last name here'
                            type="text"
                        />
                        {errors.lastName && <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>}
                    </div>
                </div>

                <div className='mt-4 lg:mt-10'>
                    <p className='text-white text-xs lg:text-xl font-bold'>Your business email</p>
                    <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={`w-full bg-white/15 outline-none text-xs text-white lg:text-xl font-bold px-3 lg:px-7 py-2 lg:py-3.5 placeholder:text-white/30 mt-2 lg:mt-5 ${errors.email ? 'border border-red-500' : ''}`}
                        placeholder='Type your email here'
                        type="text"
                    />
                    {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                </div>

                <div className='mt-4 lg:mt-10'>
                    <p className='text-white text-xs lg:text-xl font-bold'>Text your message</p>
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className={`w-full resize-none h-[160px] bg-white/15 outline-none text-xs text-white lg:text-xl font-bold px-3 lg:px-7 py-2 lg:py-3.5 placeholder:text-white/30 mt-2 lg:mt-5 ${errors.message ? 'border border-red-500' : ''}`}
                        placeholder='Type your message here'
                    />
                    {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message}</p>}
                </div>

                <div className='flex justify-center mt-8'>
                    <button
                        onClick={handleSubmit}
                        className="defaultBtnGradient mt-4 max-w-[160px] lg:max-w-[200px] w-full text-white text-xs lg:text-[20px] font-bold px-2 py-2 lg:py-4 uppercase hover:scale-[0.98] duration-100 rounded-lg sora"
                    >
                        Send
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ContactModal;
