import React from "react";
import homeHero from "../images/home-hero.png";
import NavigateBlock from "../components/NavigateBlock";
import navigate1 from "../images/navigate/1.png";
import navigate2 from "../images/navigate/2.png";
import navigate3 from "../images/navigate/3.png";
import GearUp from "../components/GearUp";
import Feture from "../components/Feture";
import blinker from "../images/blink-space.png";
import intro from "../images/intro-hero.png";
import pressHero from "../images/press-hero.png";
import iconLi from "../images/icon-li.svg";
import ConnectBlock from "../components/ConnectBlock";
import connect1 from "../images/connect-block/1.png";
import connect2 from "../images/connect-block/2.png";
import connect3 from "../images/connect-block/3.png";
import connect4 from "../images/connect-block/4.png";
import { Link } from "react-router-dom";
import { useLanguage } from "../Helpers/LanguageContext";
import { Helmet } from "react-helmet-async";
import MediaSlideBar from "../components/MediaSlideBar";
import Media from "../components/Media";

function Partners() {
  const { languageData } = useLanguage();
  return (
    <div className="bg-center overflow-hidden">
      <Helmet>
        <title>BPX - Utility</title>
      </Helmet>
      <section className="py-10 md:py-20 md:pt-28">
        <div className="max-w-[1255px] w-full m-auto px-4 relative mt-10 lg:mt-20">
          <h1 className="text-white font-bold sora text-2xl lg:text-[64px] uppercase leading-6 lg:leading-[76px] relative z-10">
            {languageData?.U_homeTitle}{" "}
            <span className="text-[#29ABE2]">
              {" "}
              {languageData?.U_homeTitleSpan1}{" "}
            </span>{" "}
            {languageData?.U_homeTitleSpan2}
            <span className="text-[#29ABE2]">
              {" "}
              {languageData?.U_homeTitleSpan3}
            </span>
          </h1>
          <div className="flex justify-center lg:justify-start gap-2 items-end">
            <div className="mt-4 mb-2 relative z-10">
              <p className="text-xs lg:text-xl text-white leading-[18px] lg:max-w-[830px] w-full lg:my-10">
                {languageData?.U_homeSubTitle}
              </p>
              <Link
                to="https://bloom-patient-4f6.notion.site/12bed321375d80988f81ed005e02d521"
                target="_blank"
              >
                <button className="defaultBtnGradient mt-4 text-white text-xs lg:text-2xl font-bold px-2 py-2 lg:py-4 uppercase hover:scale-[0.98] duration-100 rounded-lg sora">
                  {languageData?.U_homeBtn}
                </button>
              </Link>
            </div>
            <img
              className="max-w-[155px] w-full lg:hidden"
              src={homeHero}
              alt="homeHero"
            />
          </div>
        </div>
      </section>
      <img
        className="absolute right-0 top-[270px] w-[420px] hidden lg:block"
        src={homeHero}
        alt="homeHero"
      />
      <section className="px-4 py-2 md:py-20">
        <div className="w-full p-4 lg:pt-8 max-w-[1255px] m-auto">
          <div className="flex items-center gap-2 md:gap-4 sora text-white font-bold text-2xl lg:text-[64px] max-w-[1255px] m-auto">
            <div className="shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]"></div>
            <p>
              <span className="text-[#29ABE2]">
                {languageData?.U_navigateTitle}
              </span>{" "}
              {languageData?.U_navigateTitleSpan1}
              <span className="text-[#29ABE2]">
                <br className="md:hidden" />{" "}
                {languageData?.U_navigateTitleSpan2}
              </span>{" "}
              {languageData?.U_navigateTitleSpan3}
            </p>
          </div>
          <p className="text-xs lg:text-[20px] text-white mt-1 lg:my-6">
            {languageData?.U_navigateSubTitle}
          </p>
        </div>
        <div className="grid md:flex items-center justify-center max-w-[1255px] m-auto gap-4">
          <NavigateBlock
            title={languageData?.U_navigate1Title}
            span={languageData?.U_navigate1TitleSpan}
            subtitle={languageData?.U_navigate1SubTitle}
            image={navigate1}
          />
          <NavigateBlock
            title={languageData?.U_navigate2Title}
            span={languageData?.U_navigate2TitleSpan}
            subtitle={languageData?.U_navigate2SubTitle}
            image={navigate2}
          />
          <NavigateBlock
            title={languageData?.U_navigate3Title}
            span={languageData?.U_navigate3TitleSpan}
            subtitle={languageData?.U_navigate3SubTitle}
            image={navigate3}
          />
        </div>
      </section>
      <section className="px-2 pt-10 md:pt-10">
        <div className="max-w-[1255px] m-auto md:px-4">
          <p className="flex items-center gap-2 md:gap-4 sora text-white font-bold text-2xl md:text-[64px] md:leading-[60px]">
            <div className="shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]"></div>
            {languageData?.U_redefiningTitle}{" "}
            <span className="text-[#29ABE2]">
              {languageData?.U_redefiningTitleSpan}
            </span>
          </p>
          <p className="text-white text-xs md:text-xl leading-4 my-4 md:my-12">
            {languageData?.U_redefiningSubTitle}
          </p>
        </div>
      </section>
      <GearUp />
      <div className="flex justify-center mt-8">
        <Link
          to="https://bloom-patient-4f6.notion.site/12bed321375d80988f81ed005e02d521"
          target="_blank"
        >
          <button className="defaultBtnGradient text-white text-xs lg:text-2xl font-bold px-2 py-2 lg:py-4 uppercase hover:scale-[0.98] duration-100 rounded-lg sora">
            {languageData?.U_redefiningSubBtn}
          </button>
        </Link>
      </div>
      <section className="px-2 py-5 md:py-20  max-w-[1255px] m-auto md:px-4">
        <div className="lg:flex justify-between items-center gap-2 w-full">
          <div>
            <p className="flex items-center gap-2 md:gap-4 sora text-white font-bold text-2xl md:text-[64px] md:leading-[60px]">
              <div className="shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]"></div>
              {languageData?.U_expTitle}{" "}
              <span className="text-[#29ABE2]">
                {languageData?.U_expTitleSpan}
              </span>
            </p>
            <div className="max-w-[695px] w-full">
              <p className="text-xs lg:text-base text-white mt-2 lg:leading-6 lg:mt-10 lg:mb-6">
                {languageData?.U_expSubTitle}
              </p>
              <div>
                <div className="flex items-start gap-2 mt-2 lg:mt-6">
                  <img
                    className="w-3 h-3 lg:w-5 lg:h-5 mt-1 lg:mt-1.5"
                    src={iconLi}
                    alt="iconLi"
                  />
                  <p className="text-xs lg:text-base text-white lg:leading-6">
                    <span className="font-semibold">
                      {languageData?.U_expli1}
                    </span>{" "}
                    {languageData?.U_expli1Span}
                  </p>
                </div>
              </div>
              <div>
                <div className="flex items-start gap-2 mt-2 lg:mt-6">
                  <img
                    className="w-3 h-3 lg:w-5 lg:h-5 mt-1 lg:mt-1.5"
                    src={iconLi}
                    alt="iconLi"
                  />
                  <p className="text-xs lg:text-base text-white lg:leading-6">
                    <span className="font-semibold">
                      {languageData?.U_expli2}
                    </span>{" "}
                    {languageData?.U_expli2Span}
                  </p>
                </div>
              </div>
              <div>
                <div className="flex items-start gap-2 mt-2 lg:mt-6">
                  <img
                    className="w-3 h-3 lg:w-5 lg:h-5 mt-1 lg:mt-1.5"
                    src={iconLi}
                    alt="iconLi"
                  />
                  <p className="text-xs lg:text-base text-white lg:leading-6">
                    <span className="font-semibold">
                      {languageData?.U_expli3}
                    </span>{" "}
                    {languageData?.U_expli3Span}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img
            className="max-w-[200px] lg:max-w-[460px] w-full m-auto mt-6"
            src={pressHero}
            alt="pressHero"
          />
        </div>
        <div className="flex justify-center mt-8">
          <Link
            to="https://bloom-patient-4f6.notion.site/12bed321375d80988f81ed005e02d521"
            target="_blank"
          >
            <button className="defaultBtnGradient text-white text-xs lg:text-2xl font-bold px-2 py-2 lg:py-4 uppercase hover:scale-[0.98] duration-100 rounded-lg sora">
              {languageData?.U_expBtn}
            </button>
          </Link>
        </div>
      </section>
      <section className="px-2 py-5 md:py-20 relative">
        <div className="max-w-[1255px] m-auto md:px-4">
          <div className="flex items-center gap-2 md:gap-4 sora text-white font-bold text-2xl md:text-[64px] md:leading-[60px] max-w-[700px]">
            <div className="shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]"></div>
            <p>
              {languageData?.U_connectTitle}
              <span className="text-[#29ABE2]">
                {" "}
                {languageData?.U_connectTitleSpan}
              </span>
            </p>
          </div>
          <p className="text-white text-xs md:text-xl leading-4 my-4 md:my-12">
            {languageData?.U_connectSubTitle}
          </p>
          <div className="grid md:flex justify-between gap-4">
            <ConnectBlock
              title={languageData?.U_connect1Title}
              subtitle={languageData?.U_connect1SubTitle}
              image={connect1}
            />
            <ConnectBlock
              title={languageData?.U_connect2Title}
              subtitle={languageData?.U_connect2SubTitle}
              image={connect2}
            />
            <ConnectBlock
              title={languageData?.U_connect3Title}
              subtitle={languageData?.U_connect3SubTitle}
              image={connect3}
            />
          </div>
          <div className="w-full flex justify-center lg:gap-6 items-stretch border-2 border-[#833E20] p-2 bg-[#080913] mt-4 lg:px-12">
            <img
              className="max-w-[245px] lg:object-cover w-full m-auto hidden lg:block"
              src={connect4}
              alt="connect4"
            />
            <div className="flex flex-col md:flex-col-reverse justify-between h-full items-center">
              <div>
                <p className="text-white text-lg lg:text-[30px] font-bold space lg:leading-[48px] text-center lg:text-start lg:mt-4">
                  {languageData?.U_connect4Title}
                </p>
                <div>
                  <div className="flex items-start gap-2 mt-2">
                    <img
                      className="w-3 h-3 lg:w-5 lg:h-5 mt-1"
                      src={iconLi}
                      alt="iconLi"
                    />
                    <p className="text-xs lg:text-base text-white lg:leading-7">
                      <span className="font-semibold">
                        {languageData?.U_connect4li1}
                      </span>{" "}
                      {languageData?.U_connect4li1Span}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="flex items-start gap-2 mt-2 lg:mt-4">
                    <img
                      className="w-3 h-3 lg:w-5 lg:h-5 mt-1"
                      src={iconLi}
                      alt="iconLi"
                    />
                    <p className="text-xs lg:text-base text-white lg:leading-7">
                      <span className="font-semibold">
                        {languageData?.U_connect4li2}
                      </span>{" "}
                      {languageData?.U_connect4li2Span}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="flex items-start gap-2 mt-2 lg:mt-4">
                    <img
                      className="w-3 h-3 lg:w-5 lg:h-5 mt-1"
                      src={iconLi}
                      alt="iconLi"
                    />
                    <p className="text-xs lg:text-base text-white lg:leading-7">
                      <span className="font-semibold">
                        {languageData?.U_connect4li3}
                      </span>{" "}
                      {languageData?.U_connect4li3Span}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <img
                className="max-w-[245px] lg:object-cover w-full m-auto lg:hidden"
                src={connect4}
                alt="connect4"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <Link
            to="https://bloom-patient-4f6.notion.site/12bed321375d80988f81ed005e02d521"
            target="_blank"
          >
            <button className="defaultBtnGradient text-white text-xs lg:text-2xl font-bold px-2 py-2 lg:py-4 uppercase hover:scale-[0.98] duration-100 rounded-lg sora">
              {languageData?.U_connectBtn}
            </button>
          </Link>
        </div>
      </section>

      <section className=" py-5 md:py-20 relative">
        <div className="max-w-[1255px] m-auto md:px-4">
          <p className="flex items-center gap-2 md:gap-4 sora text-white font-bold text-2xl md:text-[64px] md:leading-[60px]">
            <div className="shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]"></div>
            {languageData?.U_roadmapTitle}
          </p>
          <p className="text-white text-xs md:text-xl leading-4 my-4 md:my-12">
            {languageData?.U_roadmapSubTitle}
          </p>
        </div>
        <Feture />
        <img
          className="absolute left-[-20px] top-[824px] hidden lg:block pl-4 w-[345px]"
          src={blinker}
          alt="arrow2"
        />
        <section className=" pt-5 md:pt-10 relative bg-[#1D264A33] mt-10">
          <div className="w-full max-w-[1255px] m-auto">
            <div className="flex px-2 items-center gap-2 md:gap-4 sora text-white font-bold text-2xl lg:text-[64px] max-w-[1255px] m-auto lg:leading-[80px]">
              <div className="shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]"></div>
              What are the media <br /> talking about us
            </div>
            <Media />
          </div>
          <MediaSlideBar />
        </section>
        <div className="px-2">
          <div className="bg-[#080913] border-2 overflow-hidden border-[#833E20] duration-100 max-w-[330px] md:max-w-[700px] rounded-[5px] md:rounded-2xl lg:max-w-[1233px] m-auto w-full flex md:items-start mt-6 p-4 md:p-10 relative lg:mt-24">
            <div className="md:max-w-[unset] m-auto md:m-[unset] lg:pl-6 relative z-10">
              <p className="text-white text-xl md:text-3xl lg:text-[48px] font-bold leading-6 md:leading-[50px] lg:mt-6">
                {languageData?.U_aboutTitle}
              </p>
              <p className="text-white text-xs md:text-base lg:text-[28px] leading-[18px] lg:leading-10 mt-2 max-w-[165px] md:max-w-[400px] lg:max-w-[540px] lg:my-6 lg:mt-14">
                {languageData?.U_aboutSubTitle}
              </p>
              <Link
                to="https://bloom-patient-4f6.notion.site/12bed321375d80988f81ed005e02d521"
                target="_blank"
              >
                <button className="defaultBtnGradient mt-4 text-white text-xs lg:text-2xl font-bold px-2 py-2 lg:py-4 uppercase hover:scale-[0.98] duration-100 rounded-lg sora">
                  {languageData?.U_aboutBtn}
                </button>
              </Link>
            </div>
            <img
              className=" absolute bottom-6 lg:bottom-10 right-[-20px] lg:right-14 w-[200px] lg:w-[500px]"
              src={intro}
              alt="intro"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Partners;
