import React, { useState, useEffect } from 'react';
import Slider from "react-infinite-logo-slider";
import slide1 from '../images/NFT/1.png';
import slide2 from '../images/NFT/2.png';
import slide3 from '../images/NFT/3.png';
import slide4 from '../images/NFT/4.png';
import slide5 from '../images/NFT/5.png';
import slide6 from '../images/NFT/6.png';
import sliderUp from '../images/bg-blocks/sliderUp.svg';

function GearUp() {


    return (
        <>
            <div className='mb-[-60px] relative z-10 w-full hidden lg:block bg-sliderUp h-[70px] bg-cover bg-center' src={sliderUp} alt="sliderUp" />
            <div className="justify-center overflow-hidden w-full mt-6 bg-[#1A0524] hidden lg:flex">
                <Slider
                    id="talent1"
                    width="340px"
                    duration={25}
                    blurBorders={false}
                    blurBoderColor={"#fff"}
                    toRight={true}
                >
                    <Slider.Slide>
                        <div className='max-w-[300px] w-full h-[300px] sliderBorderGradient rounded-md'>
                            <img className='w-full h-full object-cover rounded slideShadow' src={slide1} alt="slide1" />
                        </div>
                    </Slider.Slide>
                    <Slider.Slide>
                        <div className='max-w-[300px] w-full h-[300px] sliderBorderGradient rounded-md'>
                            <img className='w-full h-full object-cover rounded slideShadow' src={slide2} alt="slide2" />
                        </div>
                    </Slider.Slide>
                    <Slider.Slide>
                        <div className='max-w-[300px] w-full h-[300px] sliderBorderGradient rounded-md'>
                            <img className='w-full h-full object-cover rounded slideShadow' src={slide3} alt="slide3" />
                        </div>
                    </Slider.Slide>
                    <Slider.Slide>
                        <div className='max-w-[300px] w-full h-[300px] sliderBorderGradient rounded-md'>
                            <img className='w-full h-full object-cover rounded slideShadow' src={slide4} alt="slide4" />
                        </div>
                    </Slider.Slide>
                    <Slider.Slide>
                        <div className='max-w-[300px] w-full h-[300px] sliderBorderGradient rounded-md'>
                            <img className='w-full h-full object-cover rounded slideShadow' src={slide5} alt="slide5" />
                        </div>
                    </Slider.Slide>
                    <Slider.Slide>
                        <div className='max-w-[300px] w-full h-[300px] sliderBorderGradient rounded-md'>
                            <img className='w-full h-full object-cover rounded slideShadow' src={slide6} alt="slide6" />
                        </div>
                    </Slider.Slide>
                </Slider>
            </div>
            <div className='mt-[-60px] rotate-180 relative z-10 w-full hidden lg:block bg-sliderUp h-[70px] bg-cover bg-center' src={sliderUp} alt="sliderUp" />
            <div className="flex justify-center overflow-hidden w-full mt-6 bg-[#1A0524] lg:hidden">
                <Slider
                    id="talent1"
                    width="140px"
                    duration={15}
                    blurBorders={false}
                    blurBoderColor={"#fff"}
                    toRight={true}
                >
                    <Slider.Slide>
                        <div className='max-w-[120px] w-full h-[120px] sliderBorderGradient rounded-md'>
                            <img className='w-full h-full object-cover rounded slideShadow2' src={slide1} alt="slide1" />
                        </div>
                    </Slider.Slide>
                    <Slider.Slide>
                        <div className='max-w-[120px] w-full h-[120px] sliderBorderGradient rounded-md'>
                            <img className='w-full h-full object-cover rounded slideShadow2' src={slide2} alt="slide2" />
                        </div>
                    </Slider.Slide>
                    <Slider.Slide>
                        <div className='max-w-[120px] w-full h-[120px] sliderBorderGradient rounded-md'>
                            <img className='w-full h-full object-cover rounded slideShadow2' src={slide3} alt="slide3" />
                        </div>
                    </Slider.Slide>
                    <Slider.Slide>
                        <div className='max-w-[120px] w-full h-[120px] sliderBorderGradient rounded-md'>
                            <img className='w-full h-full object-cover rounded slideShadow2' src={slide4} alt="slide4" />
                        </div>
                    </Slider.Slide>
                    <Slider.Slide>
                        <div className='max-w-[120px] w-full h-[120px] sliderBorderGradient rounded-md'>
                            <img className='w-full h-full object-cover rounded slideShadow2' src={slide5} alt="slide5" />
                        </div>
                    </Slider.Slide>
                    <Slider.Slide>
                        <div className='max-w-[120px] w-full h-[120px] sliderBorderGradient rounded-md'>
                            <img className='w-full h-full object-cover rounded slideShadow2' src={slide6} alt="slide6" />
                        </div>
                    </Slider.Slide>
                </Slider>
            </div>
            
        </>
    );
}

export default GearUp;
